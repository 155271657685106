
'use strict';

require('./bootstrap');
var Masonry = require('masonry-layout');
var Glider = require('glider-js');
var gliders = document.querySelectorAll('.h-slider');

const gliderConf = [{
    slidesToShow: "auto",
    itemWidth: 255,
    draggable: true,
    arrows: {
        prev: '.h-slider-prev',
        next: '.h-slider-next'
    }
},
{
    slidesToShow: "auto",
    itemWidth: 255,
    draggable: true,
    arrows: {
        prev: '.h2-slider-prev',
        next: '.h2-slider-next'
    }
}];

gliders.forEach(function (item, index) {
    console.log(gliderConf[index]);
    new Glider(item, gliderConf[index]);
});

window.addEventListener('load', function () {
    var masonryActive = document.getElementsByClassName('wall-nav');
    if (masonryActive.length > 0) {
        var msnry = new Masonry('.wall-nav > ul', {
            itemSelector: '.col-lg-4',
            percentPosition: true,
            columnWidth: '.col-lg-4'
        });
    }
});

; (function (document, window, index) {
    var inputs = document.querySelectorAll('.inputfile');
    Array.prototype.forEach.call(inputs, function (input) {
        var label = input.nextElementSibling,
            labelVal = label.innerHTML;

        input.addEventListener('change', function (e) {
            var fileName = '';
            if (this.files && this.files.length > 1)
                fileName = (this.getAttribute('data-multiple-caption') || '').replace('{count}', this.files.length);
            else
                fileName = e.target.value.split('\\').pop();

            if (fileName)
                label.querySelector('span').innerHTML = fileName;
            else
                label.innerHTML = labelVal;
        });
    });
}(document, window, 0));

$(function () {

    if ($(window).width() < 768) {
        glider.destroy();
    }

    $('.form').submit(function (e) {
        e.preventDefault();
        var $curr = $(this);
        $('.submit', $curr).attr("disabled", "disabled");
        $.ajax({
            type: "POST",
            url: $curr.attr("action"),
            data: $curr.serialize(),
            dataType: "json",
            cache: false,
            success: function (data) {
                $('.submit', $curr).attr("disabled", false);
                $('[name="email"]', $curr).val('');
                iziToast.success({
                    message: data.message
                });
            },
            error: function (data) {
                $('.submit', $curr).attr("disabled", false);
                var validationErr = '';

                if (data.responseJSON != undefined) {
                    $.each(data.responseJSON.errors, function (key, value) {
                        validationErr += value[0] + '<br/>';
                    });

                    iziToast.error({
                        message: validationErr
                    });
                }
            }
        });
    });

    $('.select-nav ul li').click(function () {
        var c = $(this).parent().children().length;
        $(this).siblings().addBack().children().remove();
        var a = $(this).siblings().toggle();
        if ($(a).is(":visible") === true) {
            $(this).parents('.select-nav>ul').css('height', c * 72).addClass('opened');
        }
        else {
            $(this).parents('.select-nav>ul').css('height', 72).removeClass('opened');
            console.log($(this).attr('value')); //Value
        }
        $(this).parent().prepend(this);
    });

    var $select = $('.select-nav');

    $(document).mouseup(e => {
        if (!$select.is(e.target)
            && $select.has(e.target).length === 0) {
            var $ul = $select.children('ul');
            $ul.children('li:not(:first-child)').css('display', 'none');
            $ul.css('height', 72).removeClass('opened');
        }
    });

    $('#mainnav').on('show.bs.collapse', function () {
        $(this).parents('body').addClass('disable-scroll');
    });
    $('#mainnav').on('hide.bs.collapse', function () {
        $(this).parents('body').removeClass('disable-scroll');
    });

    $('.dropdown-toggle').attr('data-toggle', 'dropdown');

    $('.searchbox').on('focus', function () {
        $(this).next('p').hide();

    });
    $('.searchbox').on('blur', function () {
        if (!$(this).val()) {
            $(this).next('p').show();
        }
    });

    $(document).keyup(function (e) {
        if (e.keyCode == 27) { // escape key maps to keycode `27`
            $('.navbar').removeClass('open')
        }
    });
});
